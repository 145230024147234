/* eslint react/prop-types: 0 */
import React, { Component } from "react";
import { TimelineMax } from "gsap";

class RedscopeLogo extends Component {
  componentDidMount() {
    const tl = new TimelineMax({
      onComplete() {
        tl.reverse();
      },
      onReverseComplete() {
        tl.play();
      }
    });
    const ease = "Power1.easeOut";
    const elements = [
      "#R-redscope .rr",
      "#E-redscope .rr",
      "#D-redscope .rr",
      "#S-redscope .ww",
      "#C-redscope .ww",
      "#O-redscope .ww",
      "#P-redscope .ww",
      "#E-redscope-2 .ww"
    ];
    tl.timeScale(3);
    tl.staggerFromTo(
      elements,
      2,
      { drawSVG: "0%" },
      { drawSVG: "100%", force3D: true, ease },
      0.3,
      0
    );
  }

  render() {
    return (
      <svg
        id="loader-logo-outline"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="592.495px"
        height="180.939px"
        viewBox="0 0 592.495 180.939"
        enableBackground="new 0 0 592.495 180.939"
        // xml:space="preserve"
      >
        <g id="logo_-_grey_scale" display="none">
          <polygon
            display="inline"
            fill="#DB1F26"
            points="165.926,97.809 157.926,89.81 157.926,128.402 165.926,126.963"
          />
          <path
            display="inline"
            fill="#DB1F26"
            d="M180.837,70.979c-1.676,0-3.396,0.123-5.114,0.365l-33.197,5.711v67.956l16.29-3.066
          		l18.909-3.337c16.544-3.082,24.933-13.808,24.933-31.881c0-5.52-3.22-11.89-8.203-16.228c-3.645-3.173-9.841-6.695-18.66-5.471
          		l-16.808,3.015l8.276,8.275l6.854-1.247c5.207-0.923,8.884,1.051,11.054,2.87c3.156,2.646,5.195,6.816,5.195,10.623
          		c0,3.942,0,15.94-14.678,18.673l-19.762,3.557V86.56l19.555-3.506c9.606-1.336,16.329,2.493,20.287,5.938
          		c5.4,4.701,8.89,11.663,8.89,17.736c0,19.178-8.94,30.566-26.573,33.848l-0.184-0.983l0.174,0.985l-18.9,3.336l-15.602,2.937
          		l8.292,8.292l26.788-4.728c24.474-4.555,36.375-19.761,36.375-46.486C215.027,90.17,202.02,70.979,180.837,70.979z"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="96.098,154.508 96.098,136.777 129.1,130.954 129.1,120.283 96.098,126.107
          		96.098,109.189 86.799,101.603 86.799,166.533 134.099,158.468 134.099,147.802"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="84.799,98.645 124.599,92.244 124.599,80.583 72.799,89.555 72.799,153.304
          		84.799,165.304"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="267.503,73.449 276.049,81.995 282.974,70.733 274.428,62.187"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="126.599,92.682 133.599,99.681 133.599,89.779 126.599,82.001"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M342.374,62.308c-9.604-4.09-17.241-3.814-23.337,0.851
          		c-3.024,2.315-4.994,4.412-6.523,7.049c4.414-4.48,10.402-7.177,16.978-7.453c4.253-0.18,8.639,0.65,12.685,2.398
          		c0.645,0.278,3.251,1.546,5.86,2.821L342.374,62.308z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M259.292,108.369c0.979-1.558,1.197-3.342,0.646-5.304
          		c-1.01-3.603-5.415-5.497-9.674-7.329c-2.429-1.044-4.723-2.03-6.439-3.335c-4.635-3.521-6.88-9.474-5.588-14.813
          		c1.12-4.625,4.629-7.783,9.627-8.666c5.539-0.977,11.439,0.145,17.993,3.382l6.992-11.371c-4.973-2.902-10.579-4.377-16.757-4.377
          		c-2.472,0-5.033,0.233-7.682,0.701c-10.451,1.843-18.95,8.148-22.181,16.456c-4.001,10.291,0.416,21.397,10.274,25.833
          		c0.875,0.393,2.063,0.85,3.438,1.379c5.817,2.236,14.423,5.545,15.942,10.496C257.268,110.646,258.496,109.635,259.292,108.369z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M296.563,88.406c-3.363-6.554-4.406-15.109-1.516-23.454
          		c-0.042,0.074-0.085,0.147-0.127,0.222c-0.36,0.645-0.708,1.297-1.035,1.96c-0.019,0.038-0.04,0.076-0.059,0.114
          		c-0.34,0.697-0.655,1.405-0.957,2.12c-0.083,0.196-0.162,0.394-0.242,0.592c-0.24,0.594-0.467,1.194-0.68,1.8
          		c-0.052,0.147-0.108,0.292-0.158,0.439c-0.247,0.73-0.473,1.468-0.679,2.212c-0.052,0.187-0.098,0.374-0.146,0.562
          		c-0.158,0.604-0.303,1.213-0.434,1.825c-0.041,0.195-0.086,0.39-0.124,0.585c-0.151,0.764-0.284,1.533-0.391,2.308
          		c-0.02,0.137-0.031,0.274-0.049,0.411c-0.084,0.661-0.151,1.326-0.202,1.995c-0.017,0.215-0.033,0.431-0.046,0.647
          		c-0.049,0.796-0.082,1.596-0.082,2.4v0.201c0,0.554,0.015,1.102,0.038,1.646c0.007,0.152,0.019,0.303,0.027,0.455
          		c0.023,0.399,0.049,0.797,0.084,1.191c0.014,0.158,0.03,0.314,0.046,0.471c0.041,0.399,0.088,0.796,0.143,1.189
          		c0.017,0.132,0.036,0.264,0.056,0.396c0.066,0.446,0.142,0.888,0.225,1.325c0.015,0.073,0.026,0.146,0.041,0.219
          		c0.211,1.065,0.47,2.104,0.779,3.116c0.006,0.021,0.014,0.041,0.02,0.062c0.141,0.457,0.293,0.906,0.453,1.351
          		c0.045,0.125,0.093,0.247,0.14,0.37c0.125,0.332,0.255,0.661,0.391,0.986c0.072,0.171,0.145,0.341,0.22,0.51
          		c0.114,0.26,0.234,0.516,0.356,0.771c0.1,0.209,0.199,0.418,0.304,0.624c0.09,0.176,0.185,0.348,0.278,0.522
          		c0.972,1.808,2.129,3.492,3.478,5.025c0.723,0.822,1.506,1.604,2.325,2.326l0.002,0.002c0.148,0.13,0.291,0.267,0.442,0.394
          		c6.428,5.393,15.267,7.428,24.88,5.731c11.518-2.033,19.542-6.821,27.475-16.446l-8.314-6.931
          		c-4.538,4.836-8.629,8.202-14.199,10.035c-1.285,0.423-2.648,0.764-4.115,1.023C313.514,104.685,302.242,99.471,296.563,88.406z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M277.992,97.608c-0.912-0.837-1.886-1.619-2.906-2.368
          		c1.075,1.487,1.967,3.099,2.597,4.817c1.721,4.688,1.529,9.694-0.54,14.097c-5.339,11.365-19.406,14.972-25.206,15.995
          		c-2.114,0.373-4.296,0.555-6.503,0.555c-6.209,0-12.626-1.447-18.398-4.125l4.549,4.549c7.86,5.6,18.109,7.639,28.879,5.738
          		c7.491-1.322,17.763-5.957,22.058-14.667C286.61,113.912,284.748,103.799,277.992,97.608z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M251.589,128.179c5.493-0.969,18.803-4.358,23.743-14.876
          		c1.84-3.916,2.008-8.375,0.474-12.557c-1.681-4.579-5.281-8.414-9.883-10.546c-1.429-0.589-2.931-1.15-4.539-1.679
          		c-7.002-2.304-11.149-4.911-12.327-7.748c-0.516-1.243-0.463-2.497,0.156-3.729c0.75-1.49,1.939-2.489,3.535-2.969
          		c4.668-1.406,11.967,1.999,17.661,5.11l-4.768-4.769c-6.435-3.304-12.139-4.456-17.429-3.522c-4.18,0.737-7.106,3.35-8.03,7.166
          		c-1.108,4.579,0.842,9.703,4.854,12.75c1.523,1.156,3.706,2.096,6.02,3.09c4.472,1.923,9.541,4.103,10.809,8.626
          		c0.709,2.529,0.413,4.854-0.879,6.909c-1.774,2.822-5.466,4.972-9.873,5.75c-7.219,1.275-13.858-0.14-20.817-4.451l-7.941,10.913
          		C230.828,127.379,241.926,129.884,251.589,128.179z"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="14.561,169.834 1.565,172.128 9.839,180.401 22.836,178.108"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="125.253,94.165 88.158,100.131 97.375,107.649 132.534,101.445"
          />
          <path
            display="inline"
            fill="#DB1F26"
            d="M15.9,131.585c1.61-0.258,4.704-0.762,7.994-1.33l-0.067-7.376l-7.927-7.83V131.585z"
          />
          <path
            display="inline"
            fill="#DB1F26"
            d="M13.9,167.92v-22.401l13.438-2.371l18.94,18.856l16.597-2.873l-20.424-20.348l0.776-0.707
          		c5.926-5.395,8.037-10.826,6.849-17.61c-1.508-8.597-11.067-10.995-19.312-9.587c-7.118,1.216-11.35,1.977-13.791,2.418l8.28,8.179
          		c1.364-0.183,5.252-0.703,7.187-0.945c3.15-0.394,4.961,1.687,5.31,3.789c0.423,2.538-1.152,5.525-4.982,6.322
          		c-6.147,1.28-17.598,3.085-17.713,3.103l-1.155,0.182v-22.108l2.118-0.38c2.327-0.421,6.66-1.206,14.409-2.53
          		c9.191-1.569,19.874,1.269,21.618,11.212c1.249,7.128-0.884,13.101-6.706,18.717l7.712,7.684
          		c6.007-4.598,9.738-10.673,10.808-17.646c1.233-8.029-1.186-16.981-6.467-23.946c-5.556-7.329-17.144-9.631-25.719-8.119
          		L0.5,102.311v67.973L13.9,167.92z"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="15.9,168.345 23.892,176.336 23.826,156.771 15.9,148.785"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="128.035,118.44 121.112,111.511 98.098,115.322 98.098,123.723"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="16.957,147.01 25.159,155.273 34.795,153.393 26.663,145.298"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="133.08,145.951 126.77,139.374 98.098,144.391 98.098,152.124"
          />
          <polygon
            display="inline"
            fill="#DB1F26"
            points="56.278,172.002 72.833,169.083 64.609,160.86 48.01,163.734"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="553.995,29.198 544.995,21.835 544.995,35.166 553.995,33.674"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="555.273,56.562 585.931,51.152 578.652,43.873 546.337,49.251"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="579.995,42.388 586.995,49.388 586.995,39.515 579.995,32.516"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="590.918,65.958 584.651,59.691 555.995,64.411 555.995,71.964"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="481.96,43.033 473.858,34.931 473.858,52.992 481.96,51.673"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M494.048,16.574c-2.609,0-5.237,0.235-7.81,0.7l-27.777,4.901v67.973l13.398-2.366V66.797
          		l21.158-4.132c2.544-0.444,15.137-3.365,15.137-17.718c0-4.758-1.485-8.413-4.414-10.861c-3.533-2.953-9.201-4.076-15.166-3
          		l-13.615,2.117l8.234,8.234l5.264-0.771c1.012-0.149,4.488-0.493,6.718,1.43c1.087,0.937,1.661,2.266,1.661,3.842
          		c0,4.028-3.455,5.956-6.414,6.382l-18.563,3.022V31.661l16.384-2.548c6.516-1.174,12.798,0.11,16.78,3.439
          		c3.404,2.846,5.131,7.016,5.131,12.395c0,12.372-9.035,18.336-16.773,19.685l-18.485,3.611l8.303,8.303l11.189-1.974
          		c4.569-0.797,27.35-6.086,27.35-32.054C521.735,18.445,500.543,16.574,494.048,16.574z"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="553.995,74.337 553.995,64.741 553.995,58.101 544.995,50.737 544.995,86.605
          		591.995,78.48 591.995,67.802"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="583.152,14.179 546.335,20.346 555.273,27.66 590.431,21.456"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="584.495,12.693 591.495,19.693 591.495,8.82 584.495,1.82"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="542.995,47.78 577.995,41.955 577.995,31.721 542.995,37.525 542.995,18.878
          		582.495,12.261 582.495,0.594 530.694,9.566 530.694,71.728 530.694,73.309 542.995,85.407"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M352.832,69.941c1.803-2.399,6.928-9.214,8.584-11.417l-8.571-8.572l-8.585,11.414
          		L352.832,69.941z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M408.599,29.219c-22.088,0-40.128,17.614-40.841,39.533
          		c0.06,20.442,13.719,33.643,34.837,33.643c16.632,0,33.834-12.082,33.834-32.301c0-14.27-12.046-25.879-26.854-25.879
          		c-14.602,0-26.039,10.518-26.039,23.945c0,6.781,2.728,11.228,6.307,14.041c-1.753-2.632-2.703-5.945-2.703-9.516
          		c0-9.689,6.82-19.986,19.459-19.986c9.56,0,17.338,7.803,17.338,17.395c0,9.906-11.732,18.395-21.338,18.395
          		c-0.005,0-0.01,0-0.017,0c-0.477-0.03-0.867-0.015-1.289-0.042c-8.396-0.527-19.757-6.122-19.757-20.287
          		c0-14.549,12.316-25.945,28.039-25.945c15.91,0,28.854,12.506,28.854,27.879c0,21.471-18.22,34.301-35.834,34.301
          		c-11.145,0-20.435-3.683-26.9-10.086c7.45,10.095,19.424,16.659,32.904,16.659c22.538,0,40.873-18.335,40.873-40.873
          		C449.472,47.555,431.137,29.219,408.599,29.219z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M324.712,115.996c-2.329,0.411-4.619,0.614-6.846,0.614
          		c-4.513,0-8.762-0.851-12.593-2.479l1.396,1.396c0.77,0.871,1.554,1.653,2.371,2.372c6.472,5.69,15.466,7.867,25.324,6.127
          		c11.544-2.038,19.578-6.842,27.528-16.512l-8.586-8.586C345.068,108.896,336.711,113.878,324.712,115.996z"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="473.858,88.207 481.858,96.207 481.858,78.035 473.858,70.035"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M322.381,44.121c-0.551,0.138-1.097,0.285-1.639,0.444
          		c-0.216,0.063-0.434,0.125-0.648,0.191c-1.455,0.451-2.876,0.976-4.26,1.57c-0.202,0.087-0.402,0.178-0.602,0.268
          		c-0.513,0.229-1.02,0.469-1.521,0.717c-0.195,0.097-0.391,0.191-0.584,0.291c-1.335,0.687-2.629,1.439-3.878,2.254
          		c-0.178,0.116-0.353,0.235-0.528,0.354c-0.466,0.313-0.925,0.635-1.377,0.965c-0.132,0.097-0.265,0.192-0.395,0.29
          		c-13.053,11.031-13.734,26.035-8.606,36.027c3.115,6.069,8.052,10.232,13.819,12.028c-1.149-1.078-2.124-2.407-2.92-3.982
          		c-3.439-6.813-2.588-16.993-0.041-23.112c1.895-4.552,4.231-7.495,8.62-10.855c6.599-5.053,14.713-5.493,24.787-1.337l8.734-11.613
          		c-0.113-0.087-0.227-0.176-0.34-0.261c-0.223-0.166-0.445-0.328-0.669-0.485c-0.24-0.17-0.482-0.335-0.723-0.497
          		c-0.226-0.15-0.452-0.297-0.679-0.44c-0.243-0.153-0.488-0.3-0.733-0.445c-0.229-0.136-0.459-0.269-0.691-0.396
          		c-0.245-0.136-0.493-0.266-0.741-0.394c-0.235-0.121-0.471-0.24-0.708-0.354c-0.251-0.12-0.504-0.232-0.757-0.344
          		c-0.239-0.105-0.478-0.209-0.719-0.307c-0.258-0.104-0.519-0.201-0.778-0.297c-0.243-0.089-0.486-0.18-0.732-0.262
          		c-0.265-0.088-0.532-0.168-0.8-0.249c-0.248-0.074-0.496-0.15-0.747-0.217c-0.273-0.073-0.551-0.138-0.827-0.203
          		c-0.253-0.059-0.505-0.12-0.761-0.173c-0.282-0.058-0.57-0.106-0.857-0.155c-0.258-0.044-0.514-0.091-0.774-0.129
          		c-0.296-0.043-0.599-0.075-0.899-0.109c-0.259-0.03-0.516-0.063-0.778-0.085c-0.313-0.027-0.634-0.044-0.953-0.063
          		c-0.259-0.015-0.516-0.035-0.779-0.044c-0.332-0.011-0.672-0.011-1.01-0.013c-0.26-0.002-0.516-0.009-0.778-0.005
          		c-0.358,0.005-0.725,0.023-1.089,0.039c-0.254,0.01-0.503,0.015-0.76,0.03c-0.394,0.024-0.797,0.061-1.199,0.096
          		c-0.238,0.021-0.472,0.035-0.714,0.059c-0.451,0.046-0.915,0.107-1.378,0.167c-0.202,0.026-0.399,0.046-0.604,0.075
          		c-0.672,0.095-1.356,0.203-2.054,0.326c-0.763,0.135-1.517,0.296-2.265,0.47C322.822,44.009,322.601,44.066,322.381,44.121z"
          />
          <polygon
            display="inline"
            fill="#FFFFFF"
            points="472.519,89.696 459.525,91.991 467.799,100.264 480.794,97.97"
          />
        </g>
        <g id="R-redscope">
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="15.9,115.049 15.9,131.585 23.894,130.255 23.894,122.943
          		"
          />
          <path
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            d="M16.974,113.297l8.28,8.179l6.636-0.875c0,0,2.468-0.707,4.446,1.061
          		c1.978,1.768,2.763,7.254-3.567,8.98c0,0-18.031,3.28-18.868,3.284v-22.108l16.241-2.861c0,0,6.925-1.148,11.674,0.444
          		c0,0,13.24,2.684,10.057,18.888c0,0-0.88,5.268-6.531,10.549l7.712,7.684c0,0,10.548-7.153,11.124-21.403
          		c0,0,0.452-9.981-5.768-18.772c0,0-5.344-9.475-20.594-9.977c0,0-4.75,0.195-6.14,0.441c-1.39,0.245-31.174,5.501-31.174,5.501
          		v67.973l13.4-2.365v-22.401l13.438-2.371l18.94,18.856l16.597-2.873l-20.424-20.348c0,0,12.047-9.077,6.705-21.246
          		c0,0-3.631-8.578-18.018-6.717L16.974,113.297z"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="16.957,147.01 25.159,155.273 34.795,153.393
          		26.663,145.298"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="15.9,148.785 23.826,156.771 23.892,176.336 15.9,168.345
          		"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="14.561,169.834 1.565,172.128 9.839,180.401 22.836,178.108
          			"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="48.01,163.734 64.609,160.86 72.833,169.083 56.278,172.002
          			"
          />
        </g>
        <g id="E-redscope">
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="124.599,80.583 124.599,92.244 84.799,98.645
          		84.799,165.304 72.799,153.304 72.799,89.555"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="86.799,101.603 96.098,109.189 96.098,126.107
          		129.1,120.283 129.1,130.954 96.098,136.777 96.098,154.508 134.099,147.802 134.099,158.468 86.799,166.533"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="88.158,100.131 125.253,94.165 132.534,101.445
          		97.375,107.649"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="98.098,115.322 121.112,111.511 128.035,118.44
          		98.098,123.723"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="98.098,144.391 98.098,152.124 133.08,145.951
          		126.77,139.374"
          />
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="126.599,82.001 133.599,89.779 133.599,99.681
          		126.599,92.682"
          />
        </g>
        <g id="D-redscope">
          <polygon
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            points="157.926,89.81 165.926,97.809 165.926,126.963
          		157.926,128.402"
          />
          <path
            className="rr"
            fill="none"
            stroke="#db1e26"
            strokeMiterlimit="10"
            d="M158.986,88.043l8.276,8.275l6.854-1.247
          		c0,0,5.194-1.168,9.852,1.971c4.658,3.139,9.337,10.759,4.029,22.324c0,0-3.433,6.377-10.496,7.127l-21.575,4.302V86.56
          		l16.641-2.984c0,0,9.32-2.491,17.533,1.65c8.213,4.141,14.574,13.459,14.55,21.081c-0.024,7.622-0.824,20.806-13.174,29.214
          		c0,0-4.854,3.096-11.758,4.721l-36.145,6.607l8.292,8.292l27.783-4.921c0,0,14.712-2.499,23.002-11.02
          		c0,0,8.984-7.138,11.543-23.829c0,0,1.998-11.158-0.128-18.3c0,0-3.987-18.062-22.838-24.445c0,0-7.147-3.175-18.061-0.842
          		s-30.64,5.271-30.64,5.271v67.956l33.522-6.107c0,0,13.406-1.008,21.024-11.522c0,0,5.173-5.016,5.582-20.078
          		c0,0,0.927-9.763-10.081-18.287c0,0-7.196-5.708-17.463-3.866L158.986,88.043z"
          />
        </g>
        <g id="S-redscope">
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="267.503,73.449 274.428,62.187 282.974,70.733
          		276.049,81.995"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M272.85,60.934l-6.992,11.371c0,0-12.117-6.372-21.299-2.374
          		c0,0-9.928,4.216-5.555,16.232c0,0,1.834,3.959,4.822,6.238c0,0,2.749,1.701,3.949,2.239c1.2,0.538,5.626,2.493,5.626,2.493
          		s8.864,3.153,6.42,10.193c0,0-0.925,2.46-3.935,4.094c0,0-0.484-4.367-12.278-9.054l-6.023-2.364c0,0-5.448-2.119-8.516-6.19
          		c0,0-6.163-7.184-3.834-16.783c2.329-9.6,9.873-17.043,22.601-19.665C247.835,57.365,261.073,53.876,272.85,60.934z"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M227.036,126.579c0,0,16.052,8.951,35.696,0.37
          		c0,0,10.052-3.697,14.41-12.795c0,0,4.778-9.104-2.057-18.914c0,0,10.382,6.254,9.555,18.879c0,0,0.842,17.242-23.993,22.715
          		C260.648,136.833,239.362,141.677,227.036,126.579z"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M222.353,121.647l7.941-10.913c0,0,14.919,10.096,28.281,1.303
          		c0,0,7.354-5.11,1.139-13.108c0,0-1.562-2.061-8.951-5.154c0,0-6.75-1.962-9.382-7.247c0,0-5.072-9.29,3.242-14.381
          		c0,0,7.719-4.996,21.297,2.55l4.487,4.487c0,0-8.488-4.756-13.323-5.286c0,0-9.048-1.314-8.192,6.422c0,0,1.346,4.137,8.905,6.894
          		l7.377,2.684c0,0,9.639,2.845,11.669,14.97c0,0,2.362,14.443-17.898,21.409C258.948,126.277,241.331,134.051,222.353,121.647z"
          />
        </g>
        <g id="C-redscope">
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="344.26,61.367 352.845,49.953 361.416,58.525
          		352.832,69.941"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M342.374,62.308l5.663,5.665c0,0-19.509-13.112-35.523,2.235
          		c0,0,7.049-11.964,19.174-10.715C331.688,59.493,337.997,60.187,342.374,62.308z"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M353.307,98.928l8.586,8.586c0,0-9.059,12.198-22.788,15.463
          		c0,0-13.595,4.542-25.287-1.753c0,0-2.96-0.955-8.545-7.094c0,0,10.969,6.007,28.723-0.628c0,0,8.206-3.233,12.735-7.719
          		c0,0,3.263-3.034,4.611-4.577C352.691,99.663,353.307,98.928,353.307,98.928z"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M343.524,90.65l8.314,6.931c0,0-8.977,11.431-20.22,14.64
          		c0,0-15.324,5.907-28.284-1.255c0,0-11.394-5.21-13.427-21.186c0,0-1.689-12.807,5.139-24.829c0,0-6.925,17.351,7.42,31.259
          		c0,0,9.328,8.822,21.399,5.802C323.867,102.013,333.36,102.057,343.524,90.65z"
          />
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M351.343,48.621l-8.734,11.613c0,0-15.004-7.778-26.118,2.396
          		c0,0-9.514,7.088-9.504,20.797c0,0-0.85,9.934,5.176,16.093c0,0-17.209-4.718-16.748-26.154c0,0,0.219-20.844,24.184-28.453
          		C319.599,44.914,338.059,37.937,351.343,48.621z"
          />
        </g>
        <g id="O-redscope">
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M389.843,82.201c0,0-6.5-8.663,0.36-20.498
          		c0,0,6.166-11.553,20.513-8.507c0,0,11.703,2.578,13.15,15.344c0,0,2.19,11.482-13.963,18.499c0,0-10.669,5.511-22.442-4.33
          		c0,0-11.722-9.545-2.592-27.005c0,0,6.967-13.579,24.705-13.489c0,0,18.64-1.17,26.814,17.585c0,0,6.506,13.226-3.597,29.116
          		c0,0-7.638,12.436-25.001,15.11c0,0-17.841,3.536-32.097-9.717c0,0,10.435,16.378,33.246,16.656c0,0,19.4,1.547,33.449-17.9
          		c0,0,9.676-12.294,6.435-30.235c0,0-2.282-18.86-21.512-29.067c0,0-23.57-13.721-46.524,6.411c0,0-13.941,11.799-13.031,31.715
          		c0,0,0.688,15.97,13.132,24.318c0,0,20.361,14.61,42.569-0.563c0,0,12.812-7.784,12.969-25.436c0,0,0.968-13.864-13.188-22.383
          		c0,0-17.965-10.504-33.685,4.863c0,0-6.453,7.181-6.003,16.274C383.551,68.962,383.601,77.68,389.843,82.201z"
          />
        </g>
        <g id="P-redscope">
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M458.46,22.175v67.973l13.398-2.366V66.797l20.595-4.022 c0,0,9.13-1.119,13.485-8.69c0,0,4.401-7.334,0.919-15.869c0,0-3.229-8.992-17.168-7.306l-14.732,2.293l8.234,8.234l5.576-0.811 c0,0,5.068-0.722,7.213,2.374c0,0,3.742,6.279-4.577,9.125l-19.545,3.218V31.661l16.706-2.603c0,0,6.627-1.336,12.803,1.268 c0,0,9.817,3.143,8.663,17.061c0,0-0.372,10.154-10.742,15.328c0,0-2.887,1.546-7.004,2.131l-17.39,3.397l8.303,8.303l12.041-2.137 c0,0,11.577-2.072,18.878-10.358c0,0,8.291-7.933,7.619-22.933c0,0,0.396-13.772-10.732-20.198c0,0-7.525-5.574-21.639-4.094 L458.46,22.175z"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="473.858,34.931 481.96,43.033 481.96,51.673 473.858,52.992"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="473.858,70.035 481.858,78.035 481.858,96.207 473.858,88.207"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="472.519,89.696 459.525,91.991 467.799,100.264 480.794,97.97"
          />
        </g>
        <g id="E-redscope-2">
          <path
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            d="M555.001,37.555"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="544.995,21.835 553.995,29.198 553.995,33.674 544.995,35.166"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="546.335,20.346 555.273,27.66 590.431,21.456 583.152,14.179"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="584.495,1.82 584.495,12.693 591.495,19.693 591.495,8.82"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="579.995,32.516 586.995,39.515 586.995,49.388 579.995,42.388"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="585.931,51.305 555.273,56.562 546.337,49.251 578.652,43.873"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="584.651,59.691 590.918,65.958 555.995,71.964 555.995,64.411"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="591.995,67.802 591.995,78.48 544.995,86.605 544.995,50.737 553.995,58.101 553.995,74.337"
          />
          <polygon
            className="ww"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="542.995,85.407 542.995,47.78 577.995,41.955 577.995,31.721 542.995,37.525 542.995,18.878 582.495,12.261 582.495,0.594 530.694,9.566 530.694,73.309"
          />
        </g>
      </svg>
    );
  }
}

export default RedscopeLogo;
