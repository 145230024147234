import React from "react";
import Lottie from "react-lottie";
import animationData from "./animationData.json";

import "./Hero.scss";

const Hero = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="hero-section">
      <div className="hero-unit">
        <Lottie
          options={defaultOptions}
          width={"100%"}
          isClickToPauseDisabled={true}
        />
      </div>
    </div>
  );
};

export default Hero;
