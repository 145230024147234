/* eslint react/prop-types: 0 */
import React, { Component } from "react";
import playBtn from "../../../assets/images/works/redscope/showreel/showreel-play-btn.png";
import swimmerGirl01 from "../../../assets/images/works/redscope/showreel/02-swimmer-girl-01.png";
import swimmerGirl02 from "../../../assets/images/works/redscope/showreel/02-swimmer-girl-02.png";
import phoneDude01 from "../../../assets/images/works/redscope/showreel/05-phone-dude-01.png";
import phoneDude02 from "../../../assets/images/works/redscope/showreel/05-phone-dude-02.png";
import hypeShoes01 from "../../../assets/images/works/redscope/showreel/06-hype-shoes-01.png";
import hypeShoes02 from "../../../assets/images/works/redscope/showreel/06-hype-shoes-02.png";
import boxerman01 from "../../../assets/images/works/redscope/showreel/01-boxer-man.png";
import foodDudes from "../../../assets/images/works/redscope/showreel/03-food-dudes.png";
import fashionChick from "../../../assets/images/works/redscope/showreel/04-fashion-chick.png";
import { TweenMax, TimelineMax } from "gsap";

class Showreel extends Component {
  image1 = React.createRef();

  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);

    const elements = [
      "#loader-logo",
      ".boxer-character",
      ".boxer-man",
      ".swimmer-girl-container",
      ".ocean-bg",
      ".food-bg",
      ".food-dudes",
      ".fashion-chick",
      ".fashion-bg",
      ".phone-bg",
      ".phone-dude-container",
      ".hype-shoes-bg",
      ".hype-shoes-container"
    ];
    TweenMax.set(elements, { alpha: 0 });
    const tl = new TimelineMax({ repeat: -1 });
    const ease = "Power1.easeOut"; // default ease
    // Boxer man
    tl.fromTo(
      ".boxer-character, .boxer-man",
      0.4,
      { alpha: 0 },
      { alpha: 1, ease },
      "0"
    );
    tl.fromTo(
      ".boxer-character",
      6,
      { scale: 0.9, x: 0 },
      { scale: 1, x: -20, ease },
      "0"
    );
    tl.fromTo(
      ".boxer-man",
      6,
      { scale: 1.1, x: 0 },
      { scale: 1, transformOrigin: "50% 50%", ease },
      "0"
    );
    tl.to(".boxer-character, .boxer-man", 0.4, { alpha: 0, ease }, "4");
    // Swimming chick
    tl.fromTo(
      ".swimmer-girl-container, .ocean-bg",
      0.4,
      { alpha: 0 },
      { alpha: 1, ease },
      "4"
    );
    tl.fromTo(
      ".swimmer-girl-container",
      6,
      { scale: 0.9, x: 0 },
      { scale: 1, x: 20, ease },
      "4"
    );
    tl.fromTo(
      ".ocean-bg",
      6,
      { scale: 1.2, x: 0 },
      { scale: 1, transformOrigin: "50% 50%", ease },
      "4"
    );
    tl.to(".swimmer-girl-container, .ocean-bg", 0.2, { alpha: 0, ease }, "8");
    // Food dudes
    tl.fromTo(
      ".food-dudes, .food-bg",
      0.4,
      { alpha: 0 },
      { alpha: 1, ease },
      "8"
    );
    tl.fromTo(".food-dudes", 6, { scale: 0.98, x: 0 }, { scale: 1, ease }, "8");
    tl.fromTo(
      ".food-bg",
      6,
      { scale: 1.1, x: 0 },
      { scale: 1, transformOrigin: "50% 50%", ease },
      "8"
    );
    tl.to(".food-dudes, .food-bg", 0.2, { alpha: 0, ease }, "12");
    // Fashion chick
    tl.fromTo(
      ".fashion-chick, .fashion-bg",
      0.4,
      { alpha: 0 },
      { alpha: 1, ease },
      "12"
    );
    tl.fromTo(
      ".fashion-chick",
      6,
      { scale: 0.96, x: 0 },
      { scale: 1, ease },
      "12"
    );
    tl.fromTo(
      ".fashion-bg",
      6,
      { scale: 1.02, x: 0 },
      { scale: 1, transformOrigin: "50% 50%", ease },
      "12"
    );
    tl.to(".fashion-chick, .fashion-bg", 0.2, { alpha: 0, ease }, "16");
    // Phone dude
    tl.fromTo(
      ".phone-dude-container, .phone-bg",
      0.4,
      { alpha: 0 },
      { alpha: 1, ease },
      "16"
    );
    tl.fromTo(
      ".phone-dude-container",
      6,
      { scale: 0.98, x: 0 },
      { transformOrigin: "50% 100%", scale: 1, ease },
      "16"
    );
    tl.fromTo(
      ".phone-bg",
      6,
      { scale: 1.02, x: 0 },
      { scale: 1, transformOrigin: "50% 50%", ease },
      "16"
    );
    tl.to(".phone-dude-container, .phone-bg", 0.2, { alpha: 0, ease }, "20");
    // Shoe Chick
    tl.fromTo(
      ".hype-shoes-container, .hype-shoes-bg",
      0.4,
      { alpha: 0 },
      { alpha: 1, ease },
      "20"
    );
    tl.fromTo(
      ".hype-shoes-container",
      6,
      { scale: 1.03, x: 0 },
      { transformOrigin: "0% 100%", scale: 1, ease },
      "20"
    );
    tl.fromTo(
      ".hype-shoes-bg",
      6,
      { scale: 1.1, x: 0 },
      { scale: 1, transformOrigin: "50% 50%", ease },
      "20"
    );
    tl.to(
      ".hype-shoes-container, .hype-shoes-bg",
      0.2,
      { alpha: 0, ease },
      "26"
    );
  }

  render() {
    return (
      <div id="showreel-section" className="about-showreel" ref={this.image1}>
        <div className="bg-image-sections"></div>
        <div className="gradient-mask-feather"></div>
        <div className="container-showreel-thumbnail">
          <img className="showreel-play-btn" src={playBtn} alt="" />
          <div className="background-container">
            <div className="boxer-man"></div>
            <div className="ocean-bg"></div>
            <div className="swimmer-girl">
              <div className="swimmer-girl-container swimmer-inner">
                <img className="swimmer-girl-01" src={swimmerGirl01} alt="" />
              </div>
            </div>
            <div className="food-bg"></div>
            <div className="fashion-bg"></div>
            <div className="phone-dude">
              <div className="phone-dude-container phone-inner">
                <img className="phone-dude-01" src={phoneDude01} alt="" />
              </div>
            </div>
            <div className="phone-bg"></div>
            <div className="hype-shoes">
              <div className="hype-shoes-container hype-shoes-inner">
                <img className="hype-shoes-01" src={hypeShoes01} alt="" />
              </div>
            </div>
            <div className="hype-shoes-bg"></div>
          </div>
          <img className="boxer-character" src={boxerman01} alt="" />
          <div className="swimmer-girl-container">
            <img className="swimmer-girl-02" src={swimmerGirl02} alt="" />
          </div>
          <img alt="food guys" className="food-dudes" src={foodDudes} />
          <img
            alt="fashion girl"
            className="fashion-chick"
            src={fashionChick}
          />
          <div className="phone-dude">
            <div className="phone-dude-container">
              <img className="phone-dude-02" src={phoneDude02} alt="" />
            </div>
          </div>
          <div className="hype-shoes">
            <div className="hype-shoes-container">
              <img className="hype-shoes-02" src={hypeShoes02} alt="" />
            </div>
          </div>
          <div className="showreel-btn">Play showreel</div>
        </div>
      </div>
    );
  }
}

export default Showreel;
