import React from "react";
import TheHero from "../../../components/TheHero/TheHero";
import HeroImage from "../../../assets/images/works/easy-bill-pay/hero-ebp.jpg";

const info = {
  title: "Easy Bill Pay",
  summary:
    "Easy Bill Pay is a service that aims to automate and organise bill payments within one place. EBP aimed to take their service to the next level by investing in creating and redesigning their web service and App. Under the supervision of a Creative Director I've been given a much larger role and heavy involvement to help ensure the  product is lifted off the ground. From Web design, App design all the way through Marketing collateral, some of these roles include",
  cta: "Go to website",
  website: "https://www.easybillpay.com.au/",
  heroImage: HeroImage
};
const { title, summary, cta, heroImage, website } = info;

const Hero = () => {
  return (
    <TheHero
      title={title}
      summary={summary}
      cta={cta}
      heroImage={heroImage}
      website={website}
    />
  );
};

export default Hero;
