import React from "react";
import profile from "../../../assets/images/home/about/profile.jpg";
import "./About.scss";

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="about-section">
          <div className="about-image">
            <img className="profile" src={profile} alt="nyleve profile" />
          </div>
          <div className="about-description">
            <p>
              Hello there, my name is Nyleve. I am a multi-disciplinary designer
              with a focus on Motion & Web Design. I take pleasure in planning,
              developing and creating goal driven projects and watching them
              come to life to deliver results.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
