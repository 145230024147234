import React from "react";
import { Carousel } from "react-responsive-carousel";
// import { isMobileOnly } from "react-device-detect";

import image1 from "../../../assets/images/works/edm/kingeDM_hero.png";
import image2 from "../../../assets/images/works/edm/RadiorentalseDM_hero.png";

const mrecStyle = {
  width: "300px",
  height: "250px",
  margin: "auto"
};

const edmStyle = {
  width: "80%",
  margin: "0 auto"
};

const desktop = (
  <Carousel
    swipeable={true}
    dynamicHeight={false}
    showThumbs={false}
    showArrows={false}
    showStatus={false}
    useKeyboardArrows={true}
    emulateTouch={true}
    style={{ height: "800px" }}
  >
    <div style={edmStyle}>
      <img alt="king living" src={image1} />
    </div>
    <div style={edmStyle}>
      <img alt="radio rentals" src={image2} />
    </div>

    <div style={{ height: "100%", display: "flex", overflowX: "scroll" }}>
      <iframe
        title="ok"
        className="mrec"
        style={mrecStyle}
        src="https://300x250concepta-cpml2h40f.now.sh/"
      ></iframe>
      <iframe
        title="ok"
        className="mrec"
        style={mrecStyle}
        src="https://300x250conceptb-50pb9emh3.now.sh/"
      ></iframe>
      <iframe
        title="ok"
        className="mrec"
        style={mrecStyle}
        src="https://300x250conceptd-4ae6f0rny.now.sh/"
      ></iframe>
    </div>
    <div className="iframe-container">
      <iframe
        title="crescent prestige"
        src="https://www.youtube.com/embed/wnG7zOZg96E"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <div className="iframe-container">
      <iframe
        title="rysen"
        src="https://www.youtube.com/embed/qE3nCcDnaz4"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </Carousel>
);

const EDM = () => (
  <div className="carousel-works section">
    <div className="container">{desktop}</div>
  </div>
);

export default EDM;
