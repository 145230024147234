import React from "react";
import TheHero from "../../../components/TheHero/TheHero";
import HeroImage from "../../../assets/images/works/edm/hero-edm.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.scss";

const info = {
  title: "EDM HTML5 & Motion Graphics",
  summary:
    "A collection of assorted works to showcase various abilities & skillsets.",
  cta: "",
  heroImage: HeroImage
};
const { title, summary, cta, heroImage } = info;

const Hero = () => {
  return (
    <TheHero title={title} summary={summary} cta={cta} heroImage={heroImage} />
  );
};

export default Hero;
