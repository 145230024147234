import React from "react";
import { Link } from "react-router-dom";
import "./TheNav.scss";
import Logo from "../../assets/images/nav-logo.svg";

const TheNav = () => {
  return (
    <div className="nav">
      <div className="container">
        <div className="nav__container">
          <Link to="/">
            <div className="nav__logo">
              <img
                style={{ width: "190px", position: "relative" }}
                alt="nav logo"
                src={Logo}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TheNav;
