import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TheNav from "./TheNav/TheNav";
import TheFooter from "./TheFooter/TheFooter";
import Home from "../views/Home/Home";
import Works from "../views/Home/Works/Works";
import "../assets/styles/index.scss";
import EasyBillPay from "../views/Works/EasyBillPay/easy-bill-pay.component";
import KingLiving from "../views/Works/KingLiving/king-living.component";
import Microsoft from "../views/Works/Microsoft/microsoft.component";
import Redscope from "../views/Works/Redscope/redscope.component";
import EdmHtmlMotion from "../views/Works/EdmHtmlMotion/edm-html-motion.component";
import NotFound from "../views/NotFound/not-found.component";

function About() {
  return <h2>About</h2>;
}

const App = () => {
  return (
    <Router>
      <TheNav />
      <div className="app">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/works" exact component={Works} />
          <Route path="/works/easy-bill-pay" exact component={EasyBillPay} />
          <Route path="/works/king-living" exact component={KingLiving} />
          <Route path="/works/microsoft" exact component={Microsoft} />
          <Route path="/works/redscope" exact component={Redscope} />
          <Route
            path="/works/edm-html5-motion"
            exact
            component={EdmHtmlMotion}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
      <TheFooter />
    </Router>
  );
};

export default App;
