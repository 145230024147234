import React, { Component } from "react";
import appFlowImage from "../../../assets/images/works/easy-bill-pay/app-flow-design.png";
import { TweenMax } from "gsap/all";

class AppFlow extends Component {
  image1 = React.createRef();

  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "App Flow creation",
      summary:
        "My role during this stage was creating the App flow for one of the most important components of the app which is adding a bill into the system that would allow choice for automation. It would be this crucial stage that would later inform how the particular function would operate within the app and would soon mirror the web portal.",
      items: [
        "The design would be compatible across all devices and interchangeable between the web portal and mobile app.",
        "Applying an intuitive story like flow that does not require too much cognitive load on the end user.",
        "Ensuring the flow does not hit a dead end, but instead forms a cycle that would repeated allow the end user to keep adding their bills."
      ]
    };

    return (
      <div className="app-flow section">
        <div className="container">
          <h2>{result.title}</h2>
          <p>{result.summary}</p>

          <div className="base-image">
            <img ref={this.image1} src={appFlowImage} alt="phone" />
          </div>
          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AppFlow;
