import React, { Component } from "react";
import "./index.scss";
import Hero from "./hero.component";
import Summary from "./summary.component";
import Wireframe from "./wireframe.component";
import AppFlow from "./app-flow.component";
import WebDesign from "./web-design.component";
import AppDesign from "./app-design.component";
import Marketing from "./marketing.component";
import Works from "../../Home/Works/Works";

class EasyBillPay extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="easy-bill-pay">
        <Hero />
        <Summary />
        <Wireframe />
        <AppFlow />
        <WebDesign />
        <AppDesign />
        <Marketing />
        <div className="section">
          <div className="container">
            <h2 style={{ textAlign: "center", fontSize: "30px" }}>
              Other works
            </h2>
          </div>
        </div>
        <Works />
      </div>
    );
  }
}

export default EasyBillPay;
