import React from "react";
import { Link } from "react-router-dom";
import "./Works.scss";

const Works = () => {
  const works = [
    {
      id: 1,
      name: "Microsoft",
      image: require("../../../assets/images/home/works/work-tiles/microsoft.jpg"),
      logo: require("../../../assets/images/home/works/logos/logo-microsoft.png"),
      description: "Motion Graphics & Audio Engineering",
      url: "microsoft"
    },
    {
      id: 2,
      name: "King Living",
      image: require("../../../assets/images/home/works/work-tiles/king-living.jpg"),
      logo: require("../../../assets/images/home/works/logos/logo-king-living.png"),
      description: "UI/UX & Web Design",
      url: "king-living"
    },
    {
      id: 3,
      name: "Redscope",
      image: require("../../../assets/images/home/works/work-tiles/redscope.jpg"),
      logo: require("../../../assets/images/home/works/logos/logo-redscope.svg"),
      description: "Creative Direction & Web Design",
      url: "redscope"
    },
    {
      id: 4,
      name: "Easy Bill Pay",
      image: require("../../../assets/images/home/works/work-tiles/easy-bill-pay.jpg"),
      logo: require("../../../assets/images/home/works/logos/logo-easy-bill-pay.png"),
      description: "UI/UX & Web Design",
      url: "easy-bill-pay"
    },
    {
      id: 5,
      name: "EDM and banners",
      image: require("../../../assets/images/home/works/work-tiles/edm.jpg"),
      logo: require("../../../assets/images/home/works/logos/logo-edm.png"),
      description: "Marketing Collateral",
      url: "edm-html5-motion"
    }
  ];

  const renderWorksHelper = () => {
    return works.map(work => {
      return (
        <div key={work.id} className="work-tile">
          <Link to={`/works/${work.url}`}>
            <div
              className="work__bg"
              style={{ backgroundImage: "url(" + work.image + ")" }}
            ></div>
            <span style={{ opacity: 0 }}>&nbsp;</span>
            <p>{work.description}</p>
            <span className="gradient-title"></span>
            <img className="work-logo" alt="logo" src={work.logo} />
          </Link>
        </div>
      );
    });
  };

  return (
    <div id="works" className="works">
      <div className="works-section">{renderWorksHelper()}</div>
    </div>
  );
};

export default Works;
