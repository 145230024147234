import React, { Component } from "react";
import "./TheHero.scss";
import BaseButton from "../BaseButton/BaseButton";
import { TimelineMax } from "gsap/all";

class TheHero extends Component {
  constructor(props) {
    super(props);
    this.heading = React.createRef();
    this.body = React.createRef();
    this.button = React.createRef();
  }

  componentDidMount() {
    const elements = [
      this.heading.current,
      this.body.current,
      this.button.current
    ];
    const tl = new TimelineMax();
    const ease = "Power1.easeOut";
    tl.from(elements[0], 1, { autoAlpha: 0, ease }, "0.4");
    tl.from(elements[1], 1, { autoAlpha: 0, y: 10, ease }, "0.6");
    if (elements[2]) {
      tl.from(elements[2], 1, { autoAlpha: 0, y: 10, ease }, "0.8");
    }
  }

  render() {
    const { title, summary, cta, heroImage, className, website } = this.props;
    return (
      <div className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
        {className && <div className={className}></div>}
        <div className="container">
          <h1 ref={this.heading}>{title}</h1>
          <p ref={this.body}>{summary}</p>
          {cta && (
            <div
              ref={this.button}
              style={{ display: "inline-block" }}
              onClick={() => window.open(website)}
            >
              <BaseButton msg={cta} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TheHero;
