import React from "react";
import TheHero from "../../../components/TheHero/TheHero";
import HeroImage from "../../../assets/images/works/microsoft/hero-microsoft.png";

const info = {
  title: "Microsoft",
  summary:
    "Microsoft needed to create marketing material to promote their new line of easy to use apps that simplify internal organisation processes. Recruited amongst other Motion Graphic artists I helped put together their assets to bring it to life.",
  cta: "",
  heroImage: HeroImage
};
const { title, summary, cta, heroImage } = info;

const Hero = () => {
  return (
    <TheHero
      title={title}
      summary={summary}
      cta={cta}
      heroImage={heroImage}
      className="overlay"
    />
  );
};

export default Hero;
