import React from "react";
import BaseButton from "../../../components/BaseButton/BaseButton";
import Showreel from "./showreel.component";

const AnimationVideo = () => {
  const result = {
    title: "Animation & Video Editing",
    summary:
      "Redscope was willing to give me access to the raw video files of the work they were most proud of. Going through these works i extracted and patched together the moments deemed as their best work as videographers. To further the concept of videos as moving still images, I found any chance to animated the more static images of the website.",
    items: [
      "Utilisation of After effects to mock up final CSS animations",
      "Distillation of Redscopes best work into a 15-30 second video hero banner",
      "Decreased website loading time by 65% by Video optimisation",
      "Ensure all images and animations are low"
    ]
  };
  return (
    <div className="animation-video section">
      <div className="container">
        <h2>{result.title}</h2>
        <p>{result.summary}</p>
        <div className="list">
          <p className="list-title">Key considerations & Responsibilities</p>
          <ul>
            {result.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <Showreel />
        <div
          style={{ display: "inline-block" }}
          onClick={() => window.open("http://redscopefilms.com")}
        >
          <BaseButton msg="Go to website" />
        </div>
      </div>
    </div>
  );
};

export default AnimationVideo;
