import React, { Component } from "react";
import laptopImage from "../../../assets/images/works/king-living/kl-minor-page.png";
import { TweenMax } from "gsap/all";

class MinorPageDesign extends Component {
  image1 = React.createRef();
  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "Minor Page Design",
      summary: "",
      items: [
        "While the creative director has take care of the main bulk of the design, my role was to take in the design language and style laid out and apply it across pages that haven't been created yet and ensure it fits within the design context and website as a whole."
      ]
    };

    return (
      <div className="minor-page-design section">
        <div className="container">
          <h2>{result.title}</h2>
          {/* <p>{result.summary}</p> */}
          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="base-image">
            <img ref={this.image1} src={laptopImage} alt="phone" />
          </div>
          <div className="preview">
            <p>
              Image Gallery (Closer look)
              <br />
              <span style={{ display: "block" }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/king-living/minor-page/desktop.jpg")}
                >
                  Desktop
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/king-living/minor-page/tablet.jpg")}
                >
                  Tablet
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/king-living/minor-page/mobile.jpg")}
                >
                  Mobile
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MinorPageDesign;
