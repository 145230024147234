import React from "react";

const Summary = () => {
  const summary = {
    title: "Main roles & Responsibilities",
    body:
      "Creative Direction, Wire framing, Web Design, Graphic Design, Responsive Webrollout, Animation & Video Editing, Web Optimisation"
  };

  return (
    <div className="summary section">
      <div className="container">
        <h2>{summary.title}</h2>
        <p>{summary.body}</p>
      </div>
    </div>
  );
};

export default Summary;
