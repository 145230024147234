import React from "react";

const Summary = () => {
  const summary = {
    title: "Main roles & Responsibilities",
    body:
      "UI/UX Design, Wireframing, App Design, Web Design, Responsive Web Rollouts, Marketing Collateral, HTML 5 Animation"
  };

  return (
    <div className="summary section">
      <div className="container">
        <h2>{summary.title}</h2>
        <p>{summary.body}</p>
      </div>
    </div>
  );
};

export default Summary;
