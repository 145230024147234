import React, { Component } from "react";
import "../../../components/TheHero/TheHero.scss";
import HeroImage from "../../../assets/images/works/king-living/hero-kl.jpg";
import BaseButton from "../../../components/BaseButton/BaseButton";
import { TimelineMax } from "gsap/all";
import Stamp from "../../../assets/images/works/king-living/stamp.png";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.heading = React.createRef();
    this.body = React.createRef();
    this.button = React.createRef();
  }

  componentDidMount() {
    const elements = [
      this.heading.current,
      this.body.current,
      this.button.current
    ];
    const tl = new TimelineMax();
    const ease = "Power1.easeOut";
    tl.from(elements[0], 1, { autoAlpha: 0, ease }, "0.4");
    tl.from(elements[1], 1, { autoAlpha: 0, y: 10, ease }, "0.6");
    if (elements[2]) {
      tl.from(elements[2], 1, { autoAlpha: 0, y: 10, ease }, "0.8");
    }
  }

  render() {
    const info = {
      title: "King Living",
      summary:
        "A beloved Australian furniture retail chain that focuses on sleek designer chairs. My main role for this brand was to optimize and carry out responsive roll outs of the intricate award winning design lead by the creative director.",
      cta: "Go to website",
      website: "https://www.kingliving.com.au/",
      heroImage: HeroImage
    };
    const { title, summary, cta, heroImage, website } = info;
    return (
      <div className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="container">
          <h1 ref={this.heading}>{title}</h1>
          <p ref={this.body}>{summary}</p>
          {cta && (
            <div
              ref={this.button}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
              onClick={() => window.open(website)}
            >
              <BaseButton style={{ margin: "0" }} msg={cta} />
              <img style={{ width: "170px" }} src={Stamp} alt="stamp" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Hero;
