import React, { Component } from "react";
import mp4 from "../../../assets/images/works/easy-bill-pay/app-design-section.mp4";
import webm from "../../../assets/images/works/easy-bill-pay/app-design-section.webm";
import { TweenMax } from "gsap/all";

class AppDesign extends Component {
  image1 = React.createRef();

  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }
  render() {
    const result = {
      title: "UI/UX App Design",
      summary:
        "While the Webportal had taken first and foremost priority, when developing the Mobile App, the team decided to take most of the core functionality and design of the webportal to allow us to proceed into the design stage much faster",
      items: [
        "make sure the core functionality mirrors the web portal, while creating a different feel design wise",
        "To create this different design feel to create a clear separation of the web portal and mobile app, different design colour schemes were used",
        "creation of newer graphics to create a more clear distinction between web portal and phone app",
        "Applying design to functionality that works on both android and iOS"
      ]
    };
    return (
      <div className="app-design section">
        <div className="container">
          <div className="half">
            <div className="image-right">
              <h2>{result.title}</h2>
              <p>{result.summary}</p>
              <div className="list">
                <p className="list-title">
                  Key considerations & Responsibilities
                </p>
                <ul>
                  {result.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div ref={this.image1} className="base-video">
              <video autoPlay loop muted playsInline>
                <source src={mp4} type="video/mp4" />
                <source src={webm} type="video/webm" />
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppDesign;
