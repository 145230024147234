import React, { Component } from "react";
import webDesignImage from "../../../assets/images/works/redscope/red-web-design.png";
import { TweenMax } from "gsap/all";

class WebDesign extends Component {
  image1 = React.createRef();
  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "Web Design & Optimisation",
      summary:
        "The main strategy was to point the end user towards their work portfolio and an easy to find call to action. One of the main challenges was finding the balance of maintaining their high quality video footage and images whilst keep the website as a whole light easy to navigate and light on data load.",
      items: [
        "Design and execution of look and feel of their new website",
        "Implementation of appropriate UI/UX strategies to ensure end user has an understanding of who they are and their skill set capabilities",
        "Implementation of video hero banner that showcases Redscopes abilities within 15-30 seconds",
        "Decreased website loading time by 65% by Image optimisation",
        "Responsive Web roll out optimised for all devices with 3 break points"
      ]
    };
    return (
      <div className="web-design section">
        <div className="container">
          <h2>{result.title}</h2>
          <p>{result.summary}</p>

          <div className="base-image">
            <img ref={this.image1} src={webDesignImage} alt="phone" />
          </div>

          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default WebDesign;
