import React, { Component } from "react";
import graphicDesignImage from "../../../assets/images/works/redscope/red-graphic-design-section.png";
import { TweenMax } from "gsap/all";

class GraphicDesign extends Component {
  image1 = React.createRef();
  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }
  render() {
    const result = {
      title: "Graphic Design",
      summary:
        "Another task was to gather and extrapolate their stunning video stills and images to enhance the look and feel of their website.",
      items: [
        "Photo touching and manipulation",
        "Image extraction and design execution"
      ]
    };
    return (
      <div className="graphic-design section">
        <div className="container">
          <div className="half">
            <div>
              <h2>{result.title}</h2>
              <p>{result.summary}</p>
              <div className="list">
                <p className="list-title">
                  Key considerations & Responsibilities
                </p>
                <ul>
                  {result.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="base-image">
              <img
                ref={this.image1}
                src={graphicDesignImage}
                alt="phone"
                className="red-letter-a"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraphicDesign;
