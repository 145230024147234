import React, { Component } from "react";
import Scroll from "react-scroll";
import logoFB from "../../assets/images/icons/icon-fb.svg";
import logoLinkedIn from "../../assets/images/icons/icon-linkedin.svg";
import logoEmail from "../../assets/images/icons/icon-email.svg";
import upArrow from "../../assets/images/icons/icon-top.svg";

import BaseButton from "../BaseButton/BaseButton";
import "./TheFooter.scss";
const scroll = Scroll.animateScroll;

class TheFooter extends Component {
  scrollToTop = () => scroll.scrollToTop();

  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="footer-content">
            <div
              style={{
                display: "inline-block"
              }}
              onClick={() =>
                window.open(
                  "https://drive.google.com/drive/folders/1R9l-3pbGJhyNxF-K77zPaJGPpqt9ZAx4"
                )
              }
            >
              <BaseButton className="blue" msg="Download resume" />
            </div>{" "}
            <div className="footer__social">
              <a
                href="https://www.facebook.com/tontonlives"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="icon button button-cta facebook"
                  src={logoFB}
                  alt="facebook"
                />
              </a>{" "}
              <a
                href="https://linkedin.com/in/nylevealejandrino"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="icon button button-cta linkedin"
                  src={logoLinkedIn}
                  alt="linked in"
                />
              </a>{" "}
              <a href="mailto:hello@nylevedesigns.com">
                <img
                  className="icon button button-cta email"
                  src={logoEmail}
                  alt="email"
                />
              </a>{" "}
            </div>{" "}
            <div onClick={this.scrollToTop} className="footer__top">
              <img
                className="icon button button-cta top"
                src={upArrow}
                alt="up"
              />
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}

export default TheFooter;
