import React from "react";
import TheHero from "../../../components/TheHero/TheHero";
import HeroImage from "../../../assets/images/works/redscope/hero-redscope.jpg";

const info = {
  title: "Redscope",
  summary:
    "As a freelancer I was approached by video content creators Redscope and was given the chance to design their website. I was responsible for gathering all necessary content and distilling the very essence of what they are about and what they do in order for them to step forward and approach potential clients with confidence. Ever since launch the website has helped Redscope land big clients such as Sony, Adidas & Hype DC.",
  cta: "Go to website",
  website: "http://redscopefilms.com/#/",
  heroImage: HeroImage
};
const { title, summary, cta, heroImage, website } = info;

const Hero = () => {
  return (
    <TheHero
      title={title}
      summary={summary}
      cta={cta}
      heroImage={heroImage}
      website={website}
    />
  );
};

export default Hero;
