import React from "react";

const Summary = () => {
  const summary = {
    title: "Main roles & Responsibilities",
    body: "Responsive Web Rollout, Minor Page Design, Photo Touching, Testing"
  };

  return (
    <div className="summary section">
      <div className="container">
        <h2>{summary.title}</h2>
        <p>{summary.body}</p>
      </div>
    </div>
  );
};

export default Summary;
