import React, { Component } from "react";
import webDesignImage from "../../../assets/images/works/easy-bill-pay/laptop-tablet-mobile.png";
import { TweenMax } from "gsap/all";

class WebDesign extends Component {
  image1 = React.createRef();

  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "Web Design",
      summary:
        "After Initial Wireframing and App Flow were approved it was time to move on to the design stage.",
      items: [
        "Ensure quick scan-ability in maintain across all devices and platforms.",
        "Carry out key design language/Branding set out by creative director and apply it across all pages.",
        "Roll out responsive designs across all sizes to be deliverable to developers.",
        "Design new pages that were not taken into consideration in the early stages of development."
      ]
    };

    return (
      <div className="web-design section">
        <div className="container">
          <h2>{result.title}</h2>
          <p>{result.summary}</p>

          <div className="base-image">
            <img ref={this.image1} src={webDesignImage} alt="phone" />
          </div>
          <div className="preview">
            <p>
              Image Gallery (Closer look)
              <br />
              <span style={{ display: "block" }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/easy-bill-pay/web-design/desktop.png")}
                >
                  Desktop
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/easy-bill-pay/web-design/tablet.png")}
                >
                  Tablet
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/easy-bill-pay/web-design/mobile.png")}
                >
                  Mobile
                </a>
              </span>
            </p>
          </div>

          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default WebDesign;
