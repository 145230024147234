import React, { Component } from "react";
import laptopImage from "../../../assets/images/works/king-living/kl-responsive.png";
import { TweenMax } from "gsap/all";

class ResponsiveWebRollouts extends Component {
  image1 = React.createRef();
  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "Responsive Web Rollouts",
      summary: "",
      items: [
        "Responsive Roll outs with 4 break points of the intricate designs of the creative director on all platforms and devices whilst maintaining its functionality, look & feel."
      ]
    };

    return (
      <div className="responsive-web-rollouts section">
        <div className="container">
          <h2>{result.title}</h2>
          {/* <p>{result.summary}</p> */}
          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="base-image">
            <img ref={this.image1} src={laptopImage} alt="phone" />
          </div>
          <div className="preview">
            <p>
              Image Gallery (Closer look)
              <br />
              <span style={{ display: "block" }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/king-living/web-rollouts/desktop.jpg")}
                >
                  Desktop
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/king-living/web-rollouts/tablet.jpg")}
                >
                  Tablet
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/king-living/web-rollouts/mobile.jpg")}
                >
                  Mobile
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ResponsiveWebRollouts;
