import React from "react";
import RedscopeLogo from "./redscope-logo.component";

const CreativeDirection = () => {
  const result = {
    title: "Creative Direction",
    summary:
      "The initial concept and direction i wanted to take the website was drawn from their core ethos of creating messages through the art of story telling. With this in mind I've taken every details to follow the story of who they are, what they can do and of course where to find and contact them all whilst maintaining to showcase their talent of finding beauty within the world around them.",
    items: [
      "make sure the core functionality mirrors the web portal, while creating a different feel design wise",
      "To create this different design feel to create a clear separation of the web portal and mobile app, different design colour schemes were used",
      "creation of newer graphics to create a more clear distinction between web portal and phone app",
      "Applying design to functionality that works on both android and iOS"
    ]
  };
  return (
    <div className="creative-direction section">
      <div className="container">
        <h2>{result.title}</h2>
        <p>{result.summary}</p>
        <div className="half">
          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <RedscopeLogo />
        </div>
      </div>
    </div>
  );
};

export default CreativeDirection;
