import React, { Component } from "react";
import { TweenMax } from "gsap/all";

class Video extends Component {
  image1 = React.createRef();
  image2 = React.createRef();

  componentDidMount() {
    const image1 = this.image1.current;
    const image2 = this.image2.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });
    TweenMax.set(image2, { autoAlpha: 0, y: 20 });

    const observer1 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer1.unobserve(image1);
        }
      });
    }, options);
    observer1.observe(image1);

    const observer2 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image2, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer2.unobserve(image2);
        }
      });
    }, options);
    observer2.observe(image2);
  }
  render() {
    return (
      <div className="ms-video section">
        <div className="container">
          <h2>Motion graphics & Audio Mixing</h2>
          <p>
            Provided with assets, it was my task to convert storyboards and
            animate them to bring them to life using aftereffects. In order to
            bring the project to completion i took on the extra task of putting
            together the timing and mixing of all the voice over and BGM which
            are brought to facebook marketing audio standards.
          </p>
          <div className="vid section">
            <h2>BizKit</h2>
            <div ref={this.image1} className="iframe-container">
              <iframe
                title="microsoft 1 video"
                src="https://www.youtube.com/embed/a4KqUB7ayEU"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="vid section">
            <h2>BoardConnect</h2>
            <div ref={this.image2} className="iframe-container">
              <iframe
                title="microsoft 2 video"
                src="https://www.youtube.com/embed/5KpU1rcXIbk"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
