import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const NotFound = () => {
  return (
    <div className="not-found section">
      <div className="container">
        <h1>Error: 404</h1>
        <p>Page not found.</p>
        <p>
          Go to <Link to="/">Home</Link>?
        </p>
      </div>
    </div>
  );
};

export default NotFound;
