import React from "react";
import Hero from "./Hero/hero.component";
import Works from "./Works/Works";
import About from "./About/About";

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <Works />
      <About />
    </div>
  );
};

export default Home;
