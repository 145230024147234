import React, { Component } from "react";
import testingImage from "../../../assets/images/works/king-living/kl-testing.png";
import BaseButton from "../../../components/BaseButton/BaseButton";
import { TweenMax } from "gsap/all";

class Testing extends Component {
  image1 = React.createRef();
  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "Testing",
      summary: "",
      items: [
        "Making sure every PSD file from desktop to the responsive roll out is pixel perfect before handing in over to developer.",
        "Ensuring all functionality and feel is in working order during the testing phase and communicating necessary changes to the developers."
      ]
    };
    return (
      <div className="testing section">
        <div className="container">
          <div className="half">
            <div className="image-right">
              <h2>{result.title}</h2>
              <p>{result.summary}</p>
              <div className="list">
                <p className="list-title">
                  Key considerations & Responsibilities
                </p>
                <ul>
                  {result.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="base-video">
              <div className="base-image">
                <img ref={this.image1} src={testingImage} alt="phone" />
              </div>
              <div className="preview">
                <p>
                  Image Gallery (Closer look)
                  <br />
                  <span style={{ display: "block" }}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={require("../../../assets/images/works/king-living/testing/desktop.jpg")}
                    >
                      Desktop
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ display: "inline-block" }}
            onClick={() => window.open("https://www.kingliving.com.au/")}
          >
            <BaseButton msg="Go to website" />
          </div>
        </div>
      </div>
    );
  }
}

export default Testing;
