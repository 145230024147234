import React, { Component } from "react";
import Hero from "./hero.component";
import Summary from "./summary.component";
import Video from "./video.component";
import "./index.scss";
import Works from "../../Home/Works/Works";

class Microsoft extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="microsoft">
        <Hero />
        <Summary />
        <Video />
        <div className="section">
          <div className="container">
            <h2 style={{ textAlign: "center", fontSize: "30px" }}>
              Other works
            </h2>
          </div>
        </div>
        <Works />
      </div>
    );
  }
}

export default Microsoft;
