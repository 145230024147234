import React from "react";
import BaseButton from "../../../components/BaseButton/BaseButton";

const Marketing = () => {
  const result = {
    title: "Marketing Collateral",
    summary:
      "After a soft launch I was given the task to work on the marketing collateral to promote the product.",
    items: [
      "Design and development of web mail content.",
      "Design and development of animated HTML 5 Banners to promote the core functionality of the product."
    ]
  };
  return (
    <div className="marketing section">
      <div className="container">
        <h2>{result.title}</h2>
        <p>{result.body}</p>
        <div className="list">
          <ul>
            {result.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="banner-section">
          <iframe
            title="banner1"
            src="https://300x250concepta-cpml2h40f.now.sh/"
          ></iframe>
          <iframe
            title="banner2"
            src="https://300x250conceptb-50pb9emh3.now.sh/"
          ></iframe>
          <iframe
            title="banner3"
            src="https://300x250conceptd-4ae6f0rny.now.sh/"
          ></iframe>
        </div>
        <div
          style={{ display: "inline-block" }}
          onClick={() => window.open("https://www.easybillpay.com.au/")}
        >
          <BaseButton msg="Go to website" />
        </div>
      </div>
    </div>
  );
};

export default Marketing;
