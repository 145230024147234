import React from "react";
import "./BaseButton.scss";

const BaseButton = props => {
  return (
    <button
      style={props.style}
      className={`button button-cta ${props.className}`}
    >
      {props.msg}
    </button>
  );
};

export default BaseButton;
