import React, { Component } from "react";
import laptopImage from "../../../assets/images/works/easy-bill-pay/EBP-wireframe-section.png";
import { TweenMax } from "gsap/all";

class Wireframe extends Component {
  image1 = React.createRef();

  componentDidMount() {
    const image1 = this.image1.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };
    TweenMax.set(image1, { autoAlpha: 0, y: 20 });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          TweenMax.to(image1, 1, {
            autoAlpha: 1,
            y: 0,
            ease: "Power1.easeOut"
          });
          observer.unobserve(image1);
        }
      });
    }, options);
    observer.observe(image1);
  }

  render() {
    const result = {
      title: "Wireframing & UI/UX (Web Portal & App)",
      summary:
        "As the web portal and App must have a unison sense of flow it was essential from the very early stages to have this in mind. My role during the wire framing stage was to work on and expand the ideas of the Creative Director to make sure his design thinking and solution is reflected on every necessary page/section of the Web Portal/App. One of the major challenges was breaking down large chunks of information such as types of bills, the history of bills being paid, when and how they will be paid and arranging it across the web portal/app to have it quickly readable and have a sense intuitiveness when navigating to and from these pages.",
      items: [
        "Maintenance of core functionality and design language when expanding into creation of other pages.",
        "Applying an intuitive feeling when designing navigation to and from other pages.",
        "Thinking modular in terms of sections that would would need little to no arrangement adjustments when applied to different pages.",
        "Responsive design arrangement with 3 breakpoints to allow functionality across devices.",
        "Functionality that would be applicable and interchangeable to both the web portal and mobile app to ensure all changes would be reflected from one platform to the other.",
        "Apply Intuitive sense of arrangement and information architecture as to not cause information overload."
      ]
    };

    return (
      <div className="wireframe section">
        <div className="container">
          <h2>{result.title}</h2>
          <p>{result.summary}</p>

          <div className="base-image">
            <img ref={this.image1} src={laptopImage} alt="phone" />
          </div>
          <div className="preview">
            <p>
              Image Gallery (Closer look)
              <br />
              <span style={{ display: "block" }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/easy-bill-pay/wireframe/desktop.jpg")}
                >
                  Desktop
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/easy-bill-pay/wireframe/tablet.jpg")}
                >
                  Tablet
                </a>{" "}
                |{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../../assets/images/works/easy-bill-pay/wireframe/mobile.jpg")}
                >
                  Mobile
                </a>
              </span>
            </p>
          </div>

          <div className="list">
            <p className="list-title">Key considerations & Responsibilities</p>
            <ul>
              {result.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Wireframe;
